import Vue from 'vue'
//import Vuex from 'vuex'
//import axios from 'axios'
//Vue.use(Vuex)
//import VueAxios from 'vue-axios'
//Vue.use(VueAxios, axios)
//Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
import moment from 'moment-timezone'
import timezones from '@/common/timezones.js'

const namespaced = true;

const state = {
    loadingStack: 0,
    token_type: localStorage.getItem('token_type') || null,
    access_token: localStorage.getItem('access_token') || null,
    expires_in: localStorage.getItem('expires_in') || null,
    refresh_token: localStorage.getItem('refresh_token') || null,
    user: {},
    timezone: localStorage.getItem('timezone') || moment.tz.guess()
    // timezone: (localStorage.getItem('timezone') || moment.tz.guess()) == 'America/Santiago' ? 'America/Asuncion' : localStorage.getItem('timezone') || moment.tz.guess(),
};

const getters = {
    isTokenSet(state){
        return (
                state.token_type &&
                state.access_token &&
                state.expires_in &&
                state.refresh_token
        ) ? true : false;
    },
    isTokenExpires(state){
        let fecha = new Date(state.expires_in);
        if( moment().isSameOrAfter(fecha) ){
            return true;
        }else{
            return false;
        }
    },
    getRefreshToken(state){
        return state.refresh_token;
    },
    userCan(state){ return (p) => { console.log(state.user.permissions.some( e => e.name == p.name && e.category == p.category )); return state.user.permissions.some( e => e.name == p.name && e.category == p.category ) } },
    storeCan(state){ return (p) => { return  p.includes(state.user.default_store_id);  } },
    storeCant(state){ return (p) => { return  !p.includes(state.user.default_store_id);  } }

  };

const mutations = {
    incrementLoadingStack(state){
        state.loadingStack++;
    },
    decrementLoadingStack(state){
        state.loadingStack--;
    },
    setApiToken(state, credentials){
        localStorage.setItem('token_type', credentials.token_type);
        localStorage.setItem('access_token', credentials.access_token);
        localStorage.setItem('expires_in', credentials.expires_in);
        localStorage.setItem('refresh_token', credentials.refresh_token);
        Vue.axios.defaults.headers.common['Authorization'] = credentials.token_type+' '+credentials.access_token;
        state.token_type = credentials.token_type;
        state.access_token = credentials.access_token;
        state.expires_in = credentials.expires_in;
        state.refresh_token = credentials.refresh_token;
    },
    unsetApiToken(state){
        state.token_type = null;
        state.access_token = null;
        state.expires_in = null;
        state.refresh_token = null;
        localStorage.removeItem('token_type');
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('refresh_token');
    },
    setUser(state, user){
        state.user = user;
    },
    setDefaultStore(state,store){
        state.user.default_store_id = store.default_store_id;
        state.user.default_store_name = store.default_store_name;
    },
    setTimezone(state, timezone){
        localStorage.setItem('timezone', timezone);
        state.timezone = timezone;
        Vue.prototype.$moment.tz.setDefault(timezone);
    }
};

const actions = {
    login(context, credentials){
        return new Promise((resolve,reject) => {
            Vue.axios({
                url: 'auth_app/login',
                method: 'POST',
                data: {
                    username: credentials.username,
                    password: credentials.password,
                }
            }).then( response => {
                context.commit('setApiToken', { 
                    token_type: response.data.token_type,
                    access_token: response.data.access_token,
                    expires_in: moment().add(response.data.expires_in, 's').format("YYYY-MM-DD HH:mm:ss") ,
                    refresh_token: response.data.refresh_token,
                });
                resolve(response);
            }).catch( error => {
                reject(error);
            });
        });
    },
    refreshLogin(context){
        return new Promise((resolve,reject) => {
            Vue.axios({
                url: 'auth_app/refresh',
                method: 'POST',
                data: {
                    token: context.getters.getRefreshToken,
                }
            }).then( response => {
                context.commit('setApiToken', { 
                    token_type: response.data.token_type,
                    access_token: response.data.access_token,
                    expires_in: moment().add(response.data.expires_in, 's').format("YYYY-MM-DD HH:mm:ss") ,
                    refresh_token: response.data.refresh_token,
                });
                resolve(response);
            }).catch( error => {
                reject(error);
            });
        });
    },
    signout(context){
        context.commit('unsetApiToken');
    },
    fetchUser(context){
        return new Promise((resolve,reject) => {
            Vue.axios({
                url: 'auth_app/user',
                method: 'GET',
            }).then( response => {
                context.commit('setUser', response.data);
                
                if(response.data.timezone){
                    context.commit('setTimezone', response.data.timezone);
                }
                
                resolve(response);
            }).catch( error => {
                reject(error);
            });
        });
    },
    changeDefaultStore(context,store){
        return new Promise((resolve,reject) => {
            Vue.axios({
                url: 'admin/change_default_store',
                method: 'PUT',
                data: store
            }).then( response => {
                context.commit('setDefaultStore', { 
                    default_store_id: store.id,
                    default_store_name: store.name
                });
                resolve(response);
            }).catch( error => {
                reject(error);
            });
        });
    },
    changeTimezone(context, timezone){
        return new Promise((resolve, reject) => {
            if(timezones.find(e => e == timezone)){
                context.commit('setTimezone', timezone);
                resolve();
            }else{
                reject();
            }
        })
    }
};

export default {
    namespaced,
    state,
    actions,
    mutations,
    getters
};
